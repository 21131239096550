<template>
  <div class="b-page">
    <div class="b-page__content">
      <h1 class="main-page">Записавшиеся</h1>

      <div class="container block-margin-bottom">
        <div class="mini_banners">
          <div class="container block-margin">
            <div class="b-page__content">
              <input type="checkbox" v-model="check"> Только на это ПТО
              <table>
                <template v-for="(item, index) in list.value" :key="index">
                  <tr v-show="!check || (check===true && item.pto===pto && item.timestamp>currentTimeStamp)">
                    <td>{{ item.datetime }}</td>
                    <td>{{ item.pto }}</td>
                    <td>{{ item.firm }}</td>
                    <td>{{ item.inn }}</td>
                    <td>{{ item.fio }}</td>
                    <td>{{ item.phone }}</td>
                    <td>{{ item.email }}</td>
                    <td>{{ item.typets }}</td>
                    <td>{{ item.model }}</td>
                  </tr>
                </template>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {reactive, ref} from 'vue'
import myStore from '@/store/Store'
import moment from 'moment'

export default {
  name: "List",
  setup() {
    const check = ref(true)
    const pto = myStore().ptoName
    const apiUrl=myStore().apiUrl
    const list = reactive([])
    const currentTimeStamp=Date.now()/1000
    const getTimestamp=(st)=>{
      return moment(st, 'DD.MM.YYYY HH:mm').unix()
    }
    const getList = async () => {
      try {
        const response = await fetch(apiUrl+"/singups/list", {
          method: "GET",
          mode: "cors",
        });
        list.value = await response.json();
        list.value.sort(function (a, b) {
          a.timestamp=getTimestamp(a.datetime)
          b.timestamp=getTimestamp(b.datetime)
          return b.timestamp - a.timestamp;
        });
      } catch (error) {
        console.log(error);
      }
    }

    getList()
    return {
      list,
      pto,
      check,
      currentTimeStamp
    }
  }
}
</script>

<style scoped>
table {
  border: #5e5e5e 1px solid;

}

td {
  vertical-align: middle;
  padding: 10px;
  font-size: 14px;
  text-align: center;
  border-top: 2px solid #56433D;
  border-bottom: 2px solid #56433D;
  border-right: 2px solid #56433D;
}
</style>